<template lang="pug">
.main-wrapper.caixas-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Conta Bancária</b>

        Dialog.dialogApagar(header='Remover Caixa' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a agência bancária <b>{{ dialogApagar_data.nm_banco }} - {{ dialogApagar_data.nr_agencia}}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')
        

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-4
                    label.form-label Banco:
                    .p-inputgroup
                       Dropdown(v-model='filters.cd_banco' :options='options.bancos'
                                    dataKey='value' optionLabel='nm_banco' optionValue='id' placeholder='Banco'
                                    @change='applyFilters()')
                        
                .p-col-12.p-lg-4.p-md-4
                    label.form-label Estado:
                    Dropdown(v-model='filters.cd_uf' :options='options.estados' optionLabel="textAlt" optionValue='value' filter)
                .p-col-12.p-lg-4.p-md-4
                    label.form-label Cidade:
                    Dropdown(v-model='filters.cd_cidade' :options='options.cidades' optionLabel="text" optionValue='value'  filter)
                .p-col-12.p-md-3
                .p-col-12.p-md-3
                .p-col-12.p-md-3
                .p-col-12.p-md-3
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

                        
        .ta-right.mt-4
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/agencias/salvar/0/')")
            br
            br

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            Panel.datatable(header='Lista de Contas')
                DataTable(:value="list")
                    //- Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' header='Status')
                    //-     template(#body='props')
                    //-         ProgressSpinner(v-if='waitingStatus === props.data.id' strokeWidth='6' style='width: 23px; height: 23px')
                    //-         .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data.id)')
                    //-             i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='' bodyStyle='text-align: center' field='nm_banco' header='Banco' )
                    Column(headerStyle='' bodyStyle='text-align: center' field='nr_agencia' header='Nº Agência' )
                    Column(headerStyle='' bodyStyle='text-align: center' field='ds_cidade' header='Cidade' ) 
                    Column(headerStyle='width: 10%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                //- Button.p-button-raised.p-button-rounded(
                                //-     v-tooltip.top="'Visualizar'"
                                //-     icon="jam jam-eye"
                                //-     @click="$router.push(`/contas/salvar/${ props.data.id }/`)"
                                //- )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/agencias/salvar/${ props.data.id }/`)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-tooltip.top="'Apagar'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar = true; dialogApagar_data = props.data"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .caixas-listar {
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'
    
    import { Bancos, Utils, Agencia } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
    export default {
        beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'agencias') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
        created () { 
            //this.applyFilters()
            this.getBancos() 
            Utils.getUFs().then(response => {
                if(([200,201]).includes(response.status)){
                    this.options.estados = [
                        {label:' - Todos - ', value: '', textAlt:' - Todos - '}, 
                        ...response.data.map(item => ({ value: item.id, text: item.ds_sigla, textAlt: item.nm_estado }))
                    ]
                }
            })
        },
        watch: {
            'filters.cd_uf': function() {
                this.applyFilters()
                this.getCidades()
            },
            'filters.cd_cidade': function() {
                this.applyFilters()
            }
        },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, Dropdown},
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingStatus:false,
                waitingApagar: false,
                waitingUnidades: false,
                waitingCidades: false,
                waitingEstados: false,
                dialogApagar: false,
                dialogApagar_data: {},
                filters: {
                    nr_agencia: null,
                    cd_uf: null,
                    cd_cidade: null,
				},
                options:{
                    bancos: [{label:' - Todos - ', value: ''}],
                    estados: [],
                    cidades: []
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                return Agencia.findAll(params).then(response => {
                    if (([200,201]).includes(response.status)) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            getCidades() {
                //this.filters.cd_cidade = null
                if(this.filters.cd_uf) {

                    Utils.getMunicipiosEstadoId(this.filters.cd_uf).then(response => {
                        if(([200,201]).includes(response.status)){
                            this.options.cidades = [
                                { value: null, text: '- Selecione -' }, 
                                ...response.data.map(item => ({ value: item.id, text: item.nm_cidade }))
                            ]
                        }
                })
                }
            },
            getBancos (){
                this.waitingUnidades = true
                Bancos.findAll().then(response => {
                    if (response.status == 200) {
                        this.options.bancos = response.data
                    }
                this.waitingUnidades = false
                })
            },
            clearFilters () {
                Object.keys(this.filters).forEach(k => {
                    this.filters[k] = null
                })
                this.applyFilters()
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            // alterarStatus (id) {
			// 	let conta = this.list.find(a => a.id === id)
			// 	this.waitingStatus = id
			// 	ContasBancarias.save({ id, ie_status: ! conta.ie_status }).then(response => {
			// 		this.waitingStatus = 0
			// 		if (response.status === 200) {
			// 			conta['ie_status'] = ! conta.ie_status
			// 			if (conta.ie_status)
			// 				this.$toast.success('Conta bancária habilitada', { duration: 3000 })
			// 			else
			// 				this.$toast.info('Conta bancária desabilitada', { duration: 3000 })
			// 		} else if (response.status === 400) {
			// 			this.$toast.error(response.data.detail, { duration: 3000 })
			// 		}
			// 	})
			// },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            remove () {
                this.waitingApagar = true
                Agencia.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Caixa Medclub removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>